import React, { Component } from "react";
import CategoryListNode from "./CategoryListNode";
import { CategoryNodePropsType } from "@/types/ProductTypes";
import {
  lightGrey,
  primary,
  lightYellow,
  orangeSecondary,
  primaryText,
} from "@/styles/theme";
import { Avatar } from "@mui/material";
import { red } from "@mui/material/colors";

import noimg from "@/res/drawable/noimg.png";
import category from "@/pages/product/category";

class MainCategoryListNode extends Component<
  {
    category: CategoryNodePropsType;
    onClick: any;

    isPossible?: any;
    borderColor?: string;
    square?: boolean;
  },
  { isSelected: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isSelected:
        this.props.category.isSelected !== undefined &&
        this.props.category.isSelected === 1
          ? true
          : false,
    };
    if (
      this.props.borderColor !== undefined &&
      this.props.category.isSelected !== undefined &&
      this.props.category.isSelected === 1
    ) {
      this.props.onClick(this.props.category.id, this.props.category.animeId);
    }
  }

  onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.state.isSelected) {
      this.setState({
        isSelected: !this.state.isSelected,
      });
      this.props.onClick(this.props.category.id, this.props.category.animeId);
    } else {
      if (this.props.isPossible() === true) {
        this.setState({
          isSelected: !this.state.isSelected,
        });
        this.props.onClick(this.props.category.id, this.props.category.animeId);
      }
    }
  };
  render() {
    const {
      id,
      animeId,
      isVisible,
      compactThumbnailUrl,
      animeShortName,
      name,
      animeName,
      categoryType,
    } = this.props.category;
    const { borderColor, square } = this.props;

    return (
      <div
        style={{
          width: !borderColor ? "15%" : "19%",
          margin: "8px",
          marginTop: "2px !important",
          display: !borderColor
            ? square
              ? "inline-block"
              : "block"
            : "inline-block",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={!borderColor ? this.props.onClick : this.onClick}
        id={id + ""}
      >
        <div
          style={{
            width: "100%",
            height: !borderColor ? "16vw" : "19vw",
            border: !borderColor
              ? "2px solid " + orangeSecondary
              : this.state.isSelected
              ? "6px solid " + orangeSecondary
              : "6px solid " + borderColor,
            boxShadow: !borderColor
              ? "0 0px 5px 2px rgba(255, 105, 135, 0.3)"
              : "none",
            borderRadius: !borderColor ? (square ? "10%" : "50%") : "10%",
            overflow: "hidden",
          }}
        >
          <img
            src={compactThumbnailUrl ? compactThumbnailUrl : noimg.src}
            alt={animeName}
            style={{
              marginTop: "0",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              display: "block",
              top: 0,
              left: 0,
            }}
          />
        </div>
        <div
          style={{
            padding: "2px",
            fontSize: "0.6rem",
            textShadow: "0px 0px 4px #ffffff",
            fontFamily: "normal_kirakira_bold",
          }}
        >
          {!borderColor
            ? animeShortName
            : animeName.length > 6
            ? animeShortName
            : animeName}
        </div>
      </div>
    );
  }
}

export default MainCategoryListNode;
