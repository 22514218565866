import { get } from "@/redux/module/api";
import { CategoryNodePropsType } from "@/types/ProductTypes";
import React, { Component } from "react";
import MainCategoryListNode from "./MainCategoryListNode";

import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination, Autoplay } from "swiper";
import { SwiperProps } from "swiper/react";

import SwiperCore from "swiper";
import "swiper/css"; // Swiper의 CSS 파일을 import
import "swiper/css/navigation"; // Navigation 모듈의 CSS 파일을 import
import "swiper/css/pagination"; // Pagination 모듈의 CSS 파일을 import
import "swiper/css/effect-coverflow"; // Coverflow 효과 모듈의 CSS 파일을 import
import "swiper/css/effect-fade"; // Fade 효과 모듈의 CSS 파일을 import
import "swiper/css/effect-flip"; // Flip 효과 모듈의 CSS 파일을 import
import "swiper/css/effect-cube"; // Cube 효과 모듈의 CSS 파일을 import
import dynamic from "next/dynamic";
import { NextRouter, withRouter } from "next/router";
import { saveScrollPosition } from "@/Utils/Utils";

const DynamicSwiper = dynamic<SwiperProps>(
  () => import("swiper/react").then((module) => module.Swiper),
  { ssr: false }
);

interface Props {
  router: NextRouter;
}

class MainCategoryList extends Component<
  Props,
  {
    categoryIndex: number;
    categoryList: CategoryNodePropsType[];
    categoryTypeIndex: number;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      categoryIndex: 0,
      categoryTypeIndex: 0,
      categoryList: [],
    };
    SwiperCore.use([Pagination]);
  }

  componentDidMount = async () => {
    this.refreshData();
  };

  onClickCategory = async (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.currentTarget;
    const index = parseInt(target.id);
    this.setState({ categoryTypeIndex: index });
    this.refreshData();
  };

  onClick = async (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.currentTarget;
    const id = parseInt(target.id);

    this.props.router.push("/category/" + id);
    saveScrollPosition(0);
  };
  refreshData = async () => {
    let apiUrl = "";

    if (this.state.categoryTypeIndex === 0) {
      apiUrl = "product/category/anime";
    } else if (this.state.categoryTypeIndex === 1) {
      apiUrl = "product/category/comicTypes";
    } else if (this.state.categoryTypeIndex === 2) {
      apiUrl = "product/category/goodTypes";
    }

    const res = await get(apiUrl, {});

    if (res.status === 200) {
      const data = await res.data;
      this.setState({
        categoryList: data.resData,
        categoryIndex: Math.floor(data.resData.length / 10),
      });
    }
  };

  render() {
    const PAGE_SIZE = 10;
    return this.state.categoryList ? (
      <div>
        <DynamicSwiper
          pagination={true}
          modules={[Pagination]}
          className="mySwiper"
          style={{
            height: "auto",
            padding: 0,
            margin: 0,
            width: "100%",
            background: "transparent",
          }}
        >
          <Swiper>
            {/* categoryIndex 숫자만큼 map */}
            {Array.from(Array(this.state.categoryIndex + 1).keys()).map(
              (categoryIndex) => {
                return (
                  <SwiperSlide
                    key={this.state.categoryIndex}
                    style={{
                      padding: 0,
                      margin: 0,
                      background: "transparent",
                      width: "100%",
                    }}
                  >
                    <div
                      className="mainCategoryList"
                      style={{
                        display: "flex",
                        //   marginTop: "2px",
                        //   margin: "7px",
                        fontSize: "0.9rem",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        //자동줄바꿈
                        flexWrap: "wrap",
                        paddingBottom: "15px",
                      }}
                    >
                      {this.state.categoryList.length > 0 &&
                        this.state.categoryList.map((category, index) => {
                          return (
                            categoryIndex * PAGE_SIZE <= index &&
                            index < (categoryIndex + 1) * PAGE_SIZE && (
                              <MainCategoryListNode
                                key={index}
                                // id={category.id}
                                category={category}
                                onClick={this.onClick}
                              />
                            )
                          );
                        })}
                    </div>
                  </SwiperSlide>
                );
              }
            )}
          </Swiper>
        </DynamicSwiper>
      </div>
    ) : (
      <div>데이터가 없습니다.</div>
    );
  }
}

export default withRouter(MainCategoryList);
