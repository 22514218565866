import { orangePrimary, primary } from "@/styles/theme";
import React, { Component } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MainCategoryList from "./MainCategoryList";

interface Props {
  categoryType1: string;
  categoryType2: string;
  categoryType3: string;
}
class MainCategoryTab extends Component<Props, { selectedIndex: number }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedIndex: 0,
    };
  }

  getSelectedIcon = (index: number) => {
    return (
      <CheckCircleIcon
        style={{
          color: "#1c81ff",
          width: "15px",
          height: "15px",
          padding: "0",
          margin: "0",
          marginLeft: "3px",
          justifyContent: "center",
          visibility: this.state.selectedIndex === index ? "visible" : "hidden",
        }}
      />
    );
  };

  render() {
    return (
      <div>
        <div
          //   variant="body2"
          color="text.secondary"
          style={{
            display: "flex",
            fontSize: "0.8rem",
            verticalAlign: "center !important",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              // width: "33%",
              width: "100%",
              textShadow:
                this.state.selectedIndex === 0 ? "0px 0px 2px black" : "",
              backgroundColor:
                this.state.selectedIndex === 0 ? "#ffb400" : orangePrimary,
              padding: "5px",
              paddingRight: "10px",
              display: "flex",
              textAlign: "center",
              verticalAlign: "center !important",
              borderRadius: "10px",
              margin: "3px",

              //세로중앙 정렬
              justifyContent: "center",
              color: "#ffffff",
              // padding: "3px",
            }}
            onClick={(e: any) => this.setState({ selectedIndex: 0 })}
          >
            {this.props.categoryType1}
            {this.getSelectedIcon(0)}
          </div>
          {/* <div
          style={{
            textShadow:
              this.state.selectedIndex === 1 ? "0px 0px 2px black" : "",
            backgroundColor:
              this.state.selectedIndex === 1 ? "#ffb400" : orangePrimary,
            width: "33%",
            display: "inline-block",
            padding: "5px",
            paddingLeft: "10px",
            textAlign: "center",
            margin: "1px",
            color: "#ffffff",
            verticalAlign: "center",

            //세로중앙 정렬
            justifyContent: "center",
          }}
          onClick={(e: any) => this.setState({ selectedIndex: 1 })}
        >
          {this.props.categoryType2}
          {this.getSelectedIcon(1)}
        </div>
        <div
          style={{
            textShadow:
              this.state.selectedIndex === 2 ? "0px 0px 2px black" : "",
            backgroundColor:
              this.state.selectedIndex === 2 ? "#ffb400" : orangePrimary,
            color: "#ffffff",
            verticalAlign: "center",

            //세로중앙 정렬
            justifyContent: "center",
            width: "33%",
            display: "inline-block",
            padding: "5px",
            paddingLeft: "10px",
            textAlign: "center",
            margin: "1px",
          }}
          onClick={(e: any) => this.setState({ selectedIndex: 2 })}
        >
          {this.props.categoryType3}
          {this.getSelectedIcon(2)}
        </div> */}
        </div>

        <div
          style={{
            display: "block",
          }}
        >
          <MainCategoryList />
        </div>
      </div>
    );
  }
}

export default MainCategoryTab;
