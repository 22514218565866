import { CategoryNodePropsType } from "@/types/ProductTypes";
import React, { Component } from "react";
import MainCategoryListNode from "./MainCategoryListNode";
import { get } from "@/redux/module/api";

class FavoriteCategoryList extends Component<
  { profileId?: string },
  { categoryList: CategoryNodePropsType[] }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      categoryList: [],
    };
  }

  componentDidMount = async () => {
    const apiUrl =
      "member/favorite" +
      (this.props.profileId ? "/" + this.props.profileId : "");
    try {
      const res = await get(apiUrl, {});

      if (res.status === 200) {
        this.setState({
          categoryList: res.data.resData,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.currentTarget;
    const id = parseInt(target.id);
    window.location.href = "/category/" + id;
  };

  render() {
    return (
      <div className="centerContainer">
        {this.state.categoryList && this.state.categoryList.length > 0 ? (
          this.state.categoryList.map((category, index) => {
            return (
              <MainCategoryListNode
                key={index}
                // id={category.id}
                category={category}
                onClick={this.onClick}
                square={true}
              />
            );
          })
        ) : this.props.profileId ? // <div>등록 된 최애 장르가 없습니다.</div>
        null : (
          <div>최애 장르를 등록해주세요.</div>
        )}
      </div>
    );
  }
}

export default FavoriteCategoryList;
